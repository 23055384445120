@import './assets/theme.sass';
@import './assets/button.sass';
@import './assets/hero.sass';
@import './assets/split_image.sass';
@import './assets/section_what_is.sass';
@import './assets/parallax.sass';
@import './assets/table.sass';
@import './assets/form.sass';
@import './thanks-you.sass';

.main {
    color: $primary;
    font-family: 'Eastman', sans-serif;

    p {
        letter-spacing: 0.02em;
        font-size: $font-size-base;
        font-weight: 400; }

    .title {
        font-size: $font-size-title;
        font-weight: 700; }

    img {
        pointer-events: none;
        user-select: none; } }

header {
    position: sticky;
    top: 0px;
    background: #fff;
    width: 100%;
    float: {}
    z-index: 20;
    padding: 20px 0;
    box-shadow: 0 0 30px rgba(0,0,0,.1);

    img {
        max-width: 100%; } }


footer {
    width: 100%;
    padding: 20px 0 40px 0;
    background: $primary;
    float: left;
    color: #fff;
    img {
        max-width: 100%;
        margin: 20px 0px; }
    .head {
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px solid #203E6F;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            &:first-child {
                max-width: 60px; }
            &:last-child {
                max-width: 500px; } } }

    p {
        font-size: 14px!important;
        opacity: .8; }

    .sub {
        opacity: 1;
        font-size: 18px;
        font-weight: 700; }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin: 6px 0; } }

    a {
        font-size: 15px;
        color: #fff!important;
        opacity: .8; }

    .c {
        margin-top: 25px; } }


