.btn {
    display: inline-block;
    padding: 10px 25px!important;
    border-radius: 40px!important;
    outline: none!important;
    box-shadow: none!important;

    &:hover {
        background: $secondary!important;
        color: #fff!important;
        border: 1px solid $secondary!important; }

    &.primary {
        color: #fff;
        background: $primary;
        border: 1px solid $primary;
        color: #fff; }

    &.secondary {
        color: #fff;
        background: $secondary;
        border: 1px solid $secondary;
        color: #fff; } }
