.parallax {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    margin-top: 60px;
    position: relative;

    @media only screen and (max-width: 768px) {
        text-align: center; }

    img {
        object-fit: cover;
        width: inherit;
        position: absolute;

        @media only screen and (max-width: 768px) {
            left: 0;
            top: 0;
            min-width: 100%;
            min-height: 100%; } }

    .blur {
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(36px);
        border-radius: 15px;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        color: #FFFFFF;
        padding: 20px 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -42px;
        margin-left: -250px;

        @media only screen and (max-width: 768px) {
            font-size: 20px;
            display: inline-block;
            margin-left: 0px;
            left: 0;
            width: 90%;
            margin-left: 5%;
            margin-right: 5%; } } }
