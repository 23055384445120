#hero {
    width: 100%;
    min-height: 600px;
    height: 70vh;
    overflow: hidden;
    position: relative;

    &:after, &::after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0,0,0,.5);
        z-index: 1; }

    #bg.prx {
        object-fit: cover;
        width: inherit;
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        margin-top: -12%;
        z-index: 0;

        @media only screen and (max-width: 768px) {
            margin-top: 0%; } }

    .star, .plus {
        position: absolute;
        max-width: 100%;
        z-index: 2;

        &.star {
            bottom: -40px;
            right: 20%;
            max-width: 200px; }

        &.plus {
            top: 20%;
            right: 20%;

            @media only screen and (max-width: 768px) {
                top: 80%;
                right: 80%;
                max-width: 40px; } } }

    .content {
        width: 100%;
        height: 100%;
        position: relative;
        top: 15vh;
        color: #fff;
        z-index: 4;

        p {
            margin: 20px 0 40px 0; } } }
