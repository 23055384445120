.what_is {
    width: 100%;
    margin: 100px 0;
    text-align: center;
    float: left;

    .title {
        margin-bottom: 40px; }

    .box {
        width: 25%;
        float: left;
        padding: 40px;
        color: #fff;
        min-height: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;

        &:nth-child(2) {
            background: $blue; }
        &:nth-child(3) {
            background: $yellow; }
        &:nth-child(4) {
            background: $red; }
        &:nth-child(5) {
            background: $green; }


        @media only screen and (max-width: 768px) {
            width: 100%;
            margin: 0; } } }
