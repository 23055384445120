
section.thank-you {
    width: 100%;
    float: left;
    text-align: center;
    padding: 60px 0;

    a {
        margin-top: 40px; } }

section.image {
    width: 100%;
    height: 50vh;
    min-height: 500px;
    float: left;
    background: url('../img/img-parallax.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
