.table {
    width: 100%;
    margin: 40px 0 80px 0;
    float: left;
    text-align: center;
    position: relative;

    h4 {
        margin-bottom: 80px; }

    .table-c {
        background: rgba(255, 255, 255, .2);
        backdrop-filter: blur(36px);
        border-radius: 15px;
        width: 80%;
        margin: 0px 10%;
        float: left;
        box-shadow: 0 20px 20px rgba(0,0,0,.03);
        display: flex;
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            .item {
                padding: 20px!important; } }

        .item {
            width: 100%;
            float: left;
            padding: 60px 40px;

            &:nth-child(even) {
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;

                @media only screen and (max-width: 768px) {
                    border-left: none;
                    border-right: none;
                    border-top: 1px solid #eee;
                    border-bottom: 1px solid #eee; } }

            .title {
                margin-bottom: 0px; }

            .description {
                color: $grey;
                font-weight: 400;
                margin: 30px 0; }

            ul {
                list-style-type: none;
                color: $grey;
                font-size: 18px;
                font-weight: 700;
                padding: 0;

                li {
                    margin: 15px 0; } } } }


    img {
        position: absolute;
        z-index: -10;
        max-width: 100px;

        &#star {
            bottom: -80px;
            left: 10%; }

        &#rect {
            top: 100px;
            right: 10%; } } }
