.split {
    padding: 100px 0px;
    position: relative;

    .content-d {
        z-index: 2; }

    img {
        max-width: 90%;
        margin: 0 auto;
        display: block; }

    ul {
        margin-top: 30px;
        li {
            margin: 10px 0; } }

    .btn {
        margin-top: 20px; } }
