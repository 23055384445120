#form {
    width: 100%;
    margin: 200px 0 100px 0;
    float: left;

    img {
        max-width: 90%;
        @media only screen and (max-width: 768px) {
            margin-bottom: 60px; } }

    form {
        margin-top: 40px;

        input:not(.custom-control-input) {
            width: 100%;
            border: 1px solid $grey;
            border-radius: 30px;
            padding: 10px 20px;
            margin: 10px 0; }

        button {
            margin-top: 30px; } } }
