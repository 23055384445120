
// Theme colors
$primary: #1C3255;
$secondary: #4CA894;
$grey: #7D8DA6;
$blue: #0049EE;
$yellow: #F6B609;
$red: #FF4B00;
$green: #00C4AA;

$font-size-title: calc(30px + (46 - 30) * ((100vw - 300px) / (1400 - 300)));
$font-size-base: calc(15px + (16 - 15) * ((100vw - 300px) / (1400 - 300)));
